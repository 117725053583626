<template>
  <article-page :menu="menu">
    <div class="training-content" id="section-tags-settings">
      <div class="_subtitle">
        Создание, редактирование и удаление тегов
      </div>
      <alert-message type="success" full-info>
        <template #content>
          <b>Тег</b> - цветная метка, которая используется для разделения тендеров по категориям, созданным самими пользователями.
        </template>
      </alert-message>
      <span>
        Для редактирования тегов нужно перейти в раздел <accessed-link :name="Tabs.Settings.TagsSettings" target="_blank">"Управление тегами"</accessed-link>,
        или нажать на иконку тега <ui-icon :icon="UiIconNames.Icon_Tag" :size="14" /> в шапке сайта, или <span class="link" @click="openTagEditor">нажать сюда</span>. Для создания первого тега нажмите <b>"Добавить тег"</b>.
      </span>
      <span>
        Цвета и названия тегов Вы задаете сами. Для появления цветовой палитры, нажмите на селектор под цифрой 1 на картинке ниже.
        В поле под цифрой 2 введите название тега. Оно может быть любым, но его длина не должна превышать 32 символа.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" width="460px" @click="openViewer(images, 0)"/>
      <span>
        При добавлении нового тега, ему автоматически назначается рандомно выбранный цвет. Его можно изменить, выбрав из уже подготовленного списка (1), выбрав самостоятельно из цветовой палитры (2), или введя код цвета в соответствующее поле (3).
        При вводе цвета, пожалуйста, используйте HEX код (цвет формата #******). Не забывайте нажать ОК под цифрой 4, чтобы применить внесенные изменения.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt" width="400px" @click="openViewer(images, 2)"/>
      <span>
        Для сохранения или отмены изменений, воспользуйтесь иконками:
      </span>
      <ul>
        <li><ui-icon :icon="UiIconNames.Icon_Edit" /> для редактирования тега;</li>
        <li><ui-icon :icon="UiIconNames.Icon_CheckUnderline" /> для сохранения изменений;</li>
        <li><ui-icon :icon="UiIconNames.Icon_CloseBold" /> для отмены изменений;</li>
        <li><ui-icon :icon="UiIconNames.Icon_DeleteSmoothed" /> для удаления тега;</li>
      </ul>
      <article-image :src="images[1].src" :alt="images[1].alt" width="460px" @click="openViewer(images, 1)"/>
      <alert-message type="warning" full-info>
        <template #content>
          Обратите внимание, редактировать теги можно по одному. Прежде, чем перейти к редактированию или созданию следующего тега, сохраните изменения текущего.
          Кроме того, создавать, редактировать и удалять теги могут только пользователи с правами "Администратор" и "Чтение и запись".
          Остальные пользователи могут работать с уже созданными тегами.
        </template>
      </alert-message>
    </div>
    <div class="training-content" id="section-tags-lot-working">
      <div class="_subtitle">
        Работа с лотами - назначение и снятие тегов
      </div>
      <span>
        Для того, чтобы назначить тег на лот, Вам необходимо кликнуть на иконку тега <ui-icon :icon="UiIconNames.Icon_Tag" :size="14" /> в карточке лота.
        Назначение и снятие тегов доступно на всех вкладках, предназначенных для работы с тендерами (кроме Аналитики).
      </span>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
      <span>
        Кликните на тег, чтобы назначить или снять его.
      </span>
      <alert-message type="warning" full-info>
        <template #content>
          Обратите внимание, теги привязываются к тендеру, это означает, что назначенные теги будут отображаться на всех вкладках.
          Если поменять тег на вкладке "Заявки", то он поменяется и на соответствующем тендере на других вкладках.
        </template>
      </alert-message>
      <article-image :src="images[4].src" :alt="images[4].alt" @click="openViewer(images, 4)"/>
      <span>
        Назначенные теги отображаются в виде левой границы на карточке лота.
        Назначать на тендер можно неограниченное количество тегов.
      </span>
      <article-image :src="images[5].src" :alt="images[5].alt" @click="openViewer(images, 5)"/>
    </div>
    <div class="training-content" id="section-tags-filters">
      <div class="_subtitle">
        Фильтрация тендеров по тегам
      </div>
      <span>
        На вкладках "Поиск", "Заявки" и "Контракты" предусмотрен фильтр по тегам. Он находится в самой последней ячейке
        панели фильтров, под названием "Теги". Для фильтрации можно выбрать неограниченное количество тегов. В этом случае система выдаст все тендеры, на которые назначен хотя бы один выбранный тег.
      </span>
      <article-image :src="images[6].src" :alt="images[6].alt" @click="openViewer(images, 6)"/>
      <alert-message type="success" full-info>
        <template #content>
          В поиске поддерживается множественный выбор лотов, на которые можно одним нажатием назначить тег. Подробнее читайте в статье
          <accessed-link :name="Tabs.Training.MultiCardActions" target="_blank" class="orange-link">"Множественный выбор лотов"</accessed-link>,
        </template>
      </alert-message>
    </div>
  </article-page>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useImageViewer } from "@/use/other/useImageViewer";
import { useTagsEditor } from "~/globals/tags/useTagsEditor";

import ArticleImage from "~/components/pages/training/ArticleImage.vue";
import ArticlePage from "~/components/pages/training/ArticlePage.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";
import AccessedLink from "~/components/ui/links/AccessedLink.vue";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import { Tabs } from "@/router/tabs";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "TagsArticle",
  components: {
    UiIcon,
    AccessedLink,
    AlertMessage,
    ArticlePage,
    ArticleImage,
  },
  setup() {

    const menu = [
      { id: 'section-tags-settings', title: 'Создание, редактирование и удаление тегов' },
      { id: 'section-tags-lot-working', title: 'Работа с лотами - назначение и снятие тегов' },
      { id: 'section-tags-filters', title: 'Фильтрация тендеров по тегам' },
    ]

    const images = [
      { src: 'tags/first-tag.png', alt: 'Создание тега' },
      { src: 'tags/first-tag-full.png', alt: 'Редактирование тега' },
      { src: 'tags/color-picker.png', alt: 'Цветовая палитра' },
      { src: 'tags/tag-selector-icon.png', alt: 'Иконка назначения тега на лот' },
      { src: 'tags/tag-selector-view.png', alt: 'Селектор назначение тега на лот' },
      { src: 'tags/tags-on-lot.png', alt: 'Назначенные теги' },
      { src: 'tags/filter-panel.png', alt: 'Фильтр по тегам в поиске' },
    ]

    const { openViewer } = useImageViewer();
    const { openTagEditor } = useTagsEditor();

    return {
      menu,
      images,
      openViewer,
      openTagEditor,
      Tabs,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';

.orange-link {
  color: inherit;
  font-weight: bold;
}
</style>
